import React from 'react'
import styled from 'styled-components'

import Layout from '../components/Layout'
import { rhythm } from '../utils/typography'

const Wrapper = styled.div`
  padding: ${rhythm(1)};
`

const Pre = styled.pre`
  font-size: 0.6em;
  line-height: 1.2em;
  margin: ${rhythm(0.5)} 0;
  padding: ${rhythm(0.25)};
  background-color: lightgrey;
`

const Query = styled.div`
  display: flex;
  font-size: 0.8em;
  line-height: 1.2em;
  & > :not(:last-child) {
    margin-right: ${rhythm(0.5)};
  }
  & > input {
    flex-grow: 1;
  }
`

const pass = 'https://cors-anywhere.herokuapp.com/'
const URL =
  'http://feeds.soundcloud.com/users/soundcloud:users:736865263/sounds.rss'

const parseItem = item => {
  const out = {}

  const title = item.getElementsByTagName('title')[0]
  if (title) {
    out.title = title.textContent
  }

  const duration = item.getElementsByTagName('itunes:duration')[0]
  if (duration) {
    out.duration = duration.textContent
  }

  const enclosure = item.getElementsByTagName('enclosure')[0]
  if (enclosure) {
    out.audio_url = enclosure.getAttribute('url')
    out.audio_type = enclosure.getAttribute('type')
    out.audio_length = enclosure.getAttribute('length')
  }

  return out
}

export default class GetHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      isLoaded: false,
      url: URL,
      items: [],
    }
  }

  componentDidMount() {
    this.update()
  }

  update() {
    const parser = new DOMParser()

    this.setState({ isLoaded: false })
    fetch(pass + this.state.url, { cache: 'reload' })
      .then(res => res.text())
      .then(
        result => {
          const doc = parser.parseFromString(result, 'application/xml')
          const items = Array.from(doc.getElementsByTagName('item')).map(
            parseItem
          )

          this.setState({
            isLoaded: true,
            items: items,
          })
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        error => {
          this.setState({
            isLoaded: true,
            error,
          })
        }
      )
  }

  render() {
    const { data } = this.props

    return (
      <Wrapper>
        <Query>
          <button onClick={() => this.update()}>Refresh</button>
          <input
            value={this.state.url}
            onChange={c => this.setState({ url: c.target.value })}
          />
        </Query>
        <div>
          {this.state.isLoaded &&
            this.state.items.map(
              ({ title, duration, audio_url, audio_type, audio_length }) => (
                <Pre key={audio_url}>{`
title: ${title}
duration: ${duration}
audio_url: '${audio_url}'
audio_type: '${audio_type}'
audio_length: ${audio_length}
              `}</Pre>
              )
            )}
        </div>
      </Wrapper>
    )
  }
}
